import React from 'react';
import { ScreenSize, getCurrentScreenSize } from '../Services/ScreenSizeService';
import './Navbar.css';
import JetThrustersLogoWithMessage from '../Images/jta_logo_square.jpg';
import { Button, Drawer } from '@mui/material';
import BarsSolid from '../Images/bars-solid.svg';
import { Link, useLocation } from 'react-router-dom';
import ContactSalesDialog from './ContactComponents/ContactSalesDialog';

function Navbar (): React.ReactElement {
  const [isSalesDialogOpen, setIsSalesDialogOpen] = React.useState(false);
  const [screenSize, setScreenSize] = React.useState(ScreenSize.SMALL);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isDefaultPage, d] = React.useState(true);

  const location = useLocation();

  const openSalesDialog = (): void => {
    setIsSalesDialogOpen(true);
  };
  const closeSalesDialog = (): void => {
    setIsSalesDialogOpen(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);

    // Handle Screen Size Stuff
    function UpdateScreenSize (): void {
      const screenSizeCalculated = getCurrentScreenSize();
      setScreenSize(screenSizeCalculated);
    }

    if (location.pathname === '/') {
      d(true);
    }
    else {
      d(false);
    };

    UpdateScreenSize();
    window.addEventListener('resize', UpdateScreenSize);
    return () => { window.removeEventListener('resize', UpdateScreenSize); };
  }, [location]);

  // Highlight button based on current page
  React.useEffect(() => {
    const path = location.pathname;
    const navItems = document.getElementsByClassName('nav-item');
    for (let i = 0; i < navItems.length; i++) {
      const navItem = navItems[i] as HTMLAnchorElement;
      if (navItem.href.includes(path) && path !== '/') {
        navItem.classList.add('nav-item-selected');
      } else {
        navItem.classList.remove('nav-item-selected');
      }

      if (path === '/' && navItem.innerText.includes('Home')) {
        navItem.classList.add('nav-item-selected');
      }

      if (path.includes('/systems') && navItem.innerText.includes('Systems')) {
        navItem.classList.add('nav-item-selected');
      }
    }
  }, [location]);

  return (
        <div>
          <ContactSalesDialog
            isSalesDialogOpen={isSalesDialogOpen}
            closeSalesDialog={closeSalesDialog}
          />
            {screenSize !== ScreenSize.SMALL &&
              <div className={'nav-bar-container-home-page'}  style={{
                marginBottom: isDefaultPage ? 0 : 10
              }}>
                  <div className="nav-bar-icon">
                    <Link className="nav-item" to='/'><img src={JetThrustersLogoWithMessage} width={70} alt="Jet Thruster Logo with Message"/></Link>
                  </div>
                  <div className="nav-options-container header-options-container">
                      <Link className="nav-item" to='/'> <b id='nav-bar-home'>Home</b> </Link>
                      <Link className="nav-item" to='/systems'> <b id='nav-bar-systems'>Systems</b> </Link>
                      <Link className="nav-item" to='/downloads'> <b id='nav-bar-downloads'>Downloads</b> </Link>
                      <Link className="nav-item" to='/contact'> <b id='nav-bar-contact'>Contact</b> </Link>
                      <Link className="nav-item" to='/about'> <b id='nav-bar-about'>About</b> </Link>
                  </div>
                  <div className="nav-bar-button">
                      <span id='nav-bar-get-quote'>
                          <button type='button' className='get-quote-button' id='nav-bar-get-quote' onClick={openSalesDialog}style={{
                            boxShadow: '1px 6px 18px 0px rgba(0, 0, 0, 0.3)'
                          }}
                          >
                              <h5 id='nav-bar-get-quote' style = {{ marginBlockEnd: '0px', marginBlockStart: '0px', color: 'white', fontWeight: 'normal', fontSize: '18px' }}>Get a Quote &nbsp; ›</h5>
                          </button>
                      </span>
                  </div>
              </div>
            }

            {screenSize === ScreenSize.SMALL &&
                <div className="nav-bar-container-small" style={{
                  paddingBlock: 5
                }}>
                    <div className="">
                        <Link className="nav-item" to='/'><img src={JetThrustersLogoWithMessage} width={200} alt="Jet Thruster Logo with Message" style={{
                          width: 60,
                          height: 60
                        }} /></Link>
                    </div>
                    <div onClick={() => { setDrawerOpen(true); }} className="drawer-icon" >
                        <img src={BarsSolid} width={'27px'} alt="Drawer Open and Shut Icon" id='small-screen-drawer-icon'/>
                    </div>
                    <Drawer anchor={'top'} open={drawerOpen}
                            onClose={() => { setDrawerOpen(!drawerOpen); }}
                            className="drawer-container" >
                        <div className='drawer-content'>
                            <Link id='nav-bar-home' className="drawer-nav-item" to='/' onClick={() => { setDrawerOpen(!drawerOpen); }}> Home </Link>
                            <Link id='nav-bar-systems' className="drawer-nav-item" to='/systems' onClick={() => { setDrawerOpen(!drawerOpen); }}> Systems </Link>
                            <Link id='nav-bar-downloads' className="drawer-nav-item" to='/downloads' onClick={() => { setDrawerOpen(!drawerOpen); }}> Downloads </Link>
                            <Link id='nav-bar-contact' className="drawer-nav-item" to='/contact' onClick={() => { setDrawerOpen(!drawerOpen); }}> Contact </Link>
                            <Link id='nav-bar-about' className="drawer-nav-item" to='/about' onClick={() => { setDrawerOpen(!drawerOpen); }}> About </Link>

                            <span id='nav-bar-get-quote' className='nav-bar-get-quote'>
                              <button type='button' className='get-quote-button' id='nav-bar-get-quote' onClick={openSalesDialog} style={{
                                boxShadow: '1px 6px 18px 0px rgba(0, 0, 0, 0.3)'
                              }}>
                                  <h5 style = {{ marginBlockEnd: '0px', marginBlockStart: '0px', color: 'white', fontWeight: 'normal' }} id='nav-bar-get-quote'>Get a Quote &nbsp; ›</h5>
                              </button>
                          </span>
                        </div>
                    </Drawer>
                </div>
            }
        </div>
  );
}

export default Navbar;
