import { error } from 'console';
import { FormImageSubmission } from '../Models/FormImageSubmission';
import { SalesFormModel } from '../Models/SalesFormModel';
import { SupportFormModel } from '../Models/SupportFormModel';
import axios from 'axios';

export const backendUrl = process.env.REACT_APP_BACKEND_APP_URL;
export async function SubmitSupportForm (requestModel: SupportFormModel): Promise<void> {
  const formData = new FormData();
  formData.append('typeOfBoat', requestModel.typeOfBoat);
  formData.append('boatLength', requestModel.boatLength);
  formData.append('boatName', requestModel.boatName);
  formData.append('customerEmail', requestModel.customerEmail);
  formData.append('customerPhoneNumber', requestModel.customerPhoneNumber);
  formData.append('customerQuestionOrComment', requestModel.customerQuestionOrComment);
  formData.append('specificInfo', requestModel.specificInfo);
  formData.append('componentWithIssue', requestModel.componentWithIssue);
  formData.append('imageUrl', requestModel.imageUrl);
  formData.append('imageFile', requestModel.imageFile);
  formData.append('recaptchaValue', requestModel.recaptchaValue);
  // const jsonFormData = JSON.stringify(requestModel);
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  await axios.post(process.env.REACT_APP_BACKEND_APP_URL + '/jetthruster-contact-form-submission', formData).then((response) => {
    console.log(response);
  });
}

export async function SubmitSalesForm (requestModel: SalesFormModel): Promise<void> {
  // const jsonFormData = JSON.stringify(requestModel);
  const formData = new FormData();
  // formData.append('typeOfBoat', requestModel.typeOfBoat);
  // formData.append('boatLength', requestModel.boatLength);
  formData.append('boatName', requestModel.boatName);
  formData.append('customerEmail', requestModel.customerEmail);
  formData.append('customerPhoneNumber', requestModel.customerPhoneNumber);
  formData.append('customerQuestionOrComment', requestModel.customerQuestionOrComment);
  formData.append('imageFile', requestModel.imageFile);
  formData.append('recaptchaValue', requestModel.recaptchaValue);

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  await axios.post(process.env.REACT_APP_BACKEND_APP_URL + '/jetthruster-sales-form-submission', formData).then((response) => {
    console.log(response);
  });
}
